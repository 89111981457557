#auth-wrapper {
    width: 100%;
    min-height: 100vh;
    position: relative;
    background: #092756;
    background: -moz-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%),-moz-linear-gradient(top, rgba(57,173,219,.25) 0%, rgba(42,60,87,.4) 100%), -moz-linear-gradient(-45deg, #670d10 0%, #092756 100%);
    background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -webkit-linear-gradient(top, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -webkit-linear-gradient(-45deg, #670d10 0%,#092756 100%);
    background: -o-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -o-linear-gradient(top, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -o-linear-gradient(-45deg, #670d10 0%,#092756 100%);
    background: -ms-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -ms-linear-gradient(top, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -ms-linear-gradient(-45deg, #670d10 0%,#092756 100%);
    background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), linear-gradient(to bottom, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), linear-gradient(135deg, #670d10 0%,#092756 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3E1D6D', endColorstr='#092756',GradientType=1 );

    .auth-box {
        font-family: 'Roboto';
        width: 80%;
        position: absolute;
        background: #fff;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        padding: 40px 20px;

        .title {
            text-transform: uppercase;
            letter-spacing: 2px;
            text-align: center;
            margin-bottom: 30px;
            font-size: 26px;
            color: #404040; }

        input {
            width: 100%;
            padding: 15px;
            background: #f5f5f5;
            border: none;
            color: #404040;
            margin-bottom: 20px;

            &:focus {
                outline: none; } }

        button {
            width: 100%;
            background: #5ba4e8;
            padding: 12px;
            border: none;
            font-weight: 900;
            color: #fff;
            text-transform: uppercase;
            letter-spacing: 2px;

            &:focus {
                outline: none; }

            &:hover {
                background: #699aea; } }

        .footer {
            text-align: center;
            margin-top: 30px;
            margin-bottom: 0; }

        .btn-link {
            margin: 0;
            padding: 0; }

        .error {
            padding-left: 0;
            list-style: none;
            text-align: center;
            color: red;
            margin-bottom: 20px; }

        .success {
            margin-bottom: 20px;
            text-align: center;
            color: #1dec1d; } } }

@media screen and (min-width: 540px) {
    .auth-box {
        max-width: 400px; } }
