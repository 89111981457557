body {
    font-family: 'Raleway', sans-serif; }

a:hover {
    text-decoration: none; }

input:focus,
button:focus,
textarea:focus {
    outline: none; }
